import { lazy } from "react";

const Home = lazy(() => import("../../views/home"));
const Admin = lazy(() => import("../../views/admin"));
const Feedback = lazy(() => import("../../views/feedback"));
const FileUpload = lazy(() => import("../../views/upload"));

const routes = [
  { component: Home, path: "/" },
  { component: FileUpload, path: "/upload" },
  { component: Feedback, path: "/feedback" },
  { component: Admin, path: "/admin/*" },
];

export default routes;
